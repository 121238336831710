/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import "semantic-ui-css/semantic.min.css"; // Najpierw globalne style
import "./src/components/main.scss"; // Następnie własne SCSS
export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (typeof window.IntersectionObserver === `undefined`) {
    import(`intersection-observer`).then(() => {
      console.log("IntersectionObserver polyfill loaded");
    }).catch(err => {
      console.error("Failed to load IntersectionObserver polyfill", err);
    });
  }
};
